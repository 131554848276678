export enum CookieNames {
    AuthToken = 'Authorization',
    ProjectToken ='Project-Token',
    Language = 'Language',
    Sid = 'Sid',
    GeoMessageWarned = 'GeoWarned',
    Device = 'x-device-by',
    ContentType = 'Content-Type',
    Accept = 'Accept',
    // Adding by server when auth goes by number
    PHPSession = 'PHPSESSID',
}

export enum Links {
    StoreGoogle = 'https://play.google.com/store/apps/details?id=by.mts.dating',
    StoreHuawei = 'https://appgallery.huawei.com/#/app/C101709067',
}

export enum VK {
    state = 'state=VK',
    authLink = 'https://oauth.vk.com/authorize',
    apiLink = 'https://api.vk.com/method/users.get',
    reqFields = 'first_name,photo_max_orig,city,sex,bdate',
    appID = '51612088',
    apiVersion = '5.131',
}

export enum OK {
    state = 'state=OK',
    authLink = 'https://connect.ok.ru/oauth/authorize',
    apiLink = 'https://api.ok.ru/api/users/getCurrentUser',
    reqFields = 'birthday,first_name,gender,pic_full,location',
    appKey = 'CGLCFCLGDIHBABABA',
    appID = '512001921461',
}

export enum UserStatus {
    Authorized = 'Authorized',
    Guest = 'Guest',
}

export enum Language {
    Ru = 'ru',
    Uz = 'uz',
    Default = 'ru',
}

export enum GalleryImg {
    minSize = 51200,
    maxSize = 10485760,
    formats = 'jpg/jpeg/png',
}

export enum MessageImg {
    minSize = 0,
    maxSize = 10485760,
    formats = 'jpg/jpeg/png',
}

export enum MessageVoice {
    maxSize = 10485760,
    maxDuration = 300000,
}

export enum TextLength {
    chat = 1000,
    bio = 80,
    feedback = 200,
    name = 16,
}

export enum Defaults {
    birthday = '1991-09-11',
    ModeratorId = 0,
}

export const RedirectErrors = [401, 402, 403, 409, 429, 500];

export const LP_STATUS_4 = '4';         // Blacklist error
export const LP_STATUS_12 = '12';       // Subscribe error

export const VAPID_KEY ='BDgVTQaYiFVjEC-n06gzpG0D5kJRmsWM8JhAZRF7LMZlxE2RruEDWelx2JbAr22gYzEoVu5Wwsdj-mPx73S7ehM';

export const FIREBASE_CONFIG = {
    apiKey: "AIzaSyBIL4iNzUKWrW8_i6LJVyV9VDLNr5Nj-jA",
    authDomain: "datinguzb.firebaseapp.com",
    projectId: "datinguzb",
    storageBucket: "datinguzb.appspot.com",
    messagingSenderId: "997967229009",
    appId: "1:997967229009:web:40005aefb20c95d3090870"
};

export const MINSK_CITY_GEO:[number, number] = [53.902296, 27.561862];

export const SEARCH_PARAM = 'hobby';