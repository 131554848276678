import styled from 'styled-components/macro';
import { createGlobalStyle } from 'styled-components';

export const WithScroll = styled.div`
    overflow-x: hidden;
    
    ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background: #dedede;
    } 
    
    ::-webkit-scrollbar-thumb {
        background: #afafaf;
    }
`;

export const GlobalStyles = createGlobalStyle`
    :root {
        --brand-color-1: #652d86;
        
        --app-color-online: #00b48c;
        --app-color-hover: #eff0f5;
        --app-color-overflow: rgba(0,0,0,.7);
        --app-color-link: #0083be;
        
        --page-sz-max-width: 560px;
        --page-sz-min-width: 320px;
        --page-sz-height: calc(100% - 50px);
        --page-sz-padding: 28px;
        --page-bg-primary: #fff;
        --page-bg-secondary: #eff0f5;
        
        --header-sz-height: 50px;
        
        --message-bg-income: #fff;
        --message-bg-out: #606c79;

        --label-sz-margin: 0 0 8px 0;
        --label-sz-padding: 0 0 0 2px;

        --input-sz-padding: 12px;
        --input-sz-radius: 12px;
        --input-color-border: #3e3e3e;
        --input-color-disabled: #adafaf;
        --input-color-placeholder: #adafaf;
        --input-color-focus: #0083be;
        --input-color-filled: #3e3e3e;
        --input-color-empty: #adafaf;
        
        --btn-sz-lg: 100%;
        --btn-sz-md: 180px;
        --btn-sz-sm: 140px;
        --btn-sz-radius: 12px;
        --btn-sz-padding: 12px;
        --btn-color-disabled: #f2f3f7;
        --btn-color-transparent: rgba(0,0,0,.6);
        --btn-color-focus: 0 0 1.5px 1.5px rgba(56,152,225,.28);

        --text-sz-title: 28px;
        --text-sz-head: 22px;
        --text-sz-subhead: 18px;
        --text-sz-base: 16px;
        --text-sz-body: 14px;
        --text-sz-note: 11px;
        --text-sz-hint: 10px;
        --text-color-primary: #3e3e3e;
        --text-color-secondary: #adafaf;
        --text-color-light: #fff;
        
        --icon-sz-width: 16px;
        --icon-sz-height: 16px;
        
        --font-normal: 'Roboto Regular', Arial sans-serif;
        --font-bold: 'Roboto Bold', Arial sans-serif;
    }
`;